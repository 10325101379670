.App {
  text-align: center;
  font-weight: bold;
  background-color: #fffaf5;
}


/*

FONTS

Axia Bold
font-family: axia, sans-serif;
font-weight: 700;
font-style: normal;

Axia Bold Italic
font-family: axia, sans-serif;
font-weight: 700;
font-style: italic;

Axia Italic
font-family: axia, sans-serif;
font-weight: 400;
font-style: italic;

Axia Regular
font-family: axia, sans-serif;
font-weight: 400;
font-style: normal;

Axia Black Italic
font-family: axia, sans-serif;
font-weight: 900;
font-style: italic;

Axia Black
font-family: axia, sans-serif;
font-weight: 900;
font-style: normal;

Axia Light Italic
font-family: axia, sans-serif;
font-weight: 300;
font-style: italic;

Axia Light
font-family: axia, sans-serif;
font-weight: 300;
font-style: normal;

Axia Stencil Black
font-family: axia-stencil, sans-serif;
font-weight: 900;
font-style: normal;

Axia Stencil Light
font-family: axia-stencil, sans-serif;
font-weight: 300;
font-style: normal;
*/