/* TODO: can I import these styles in pmp_theme for consistency? */

.nice-dates-navigation, .nice-dates-day {
    color: #111;
    height: 1rem;
    font-weight: 700;
    text-decoration: none;
    color: black;
    font-family: 'Arial';
}

.nice-dates-day_month {
    line-height: 0.5;
}
